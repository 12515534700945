@import "~mdb-ui-kit/css/mdb.min.css";
body {
  background-color: black !important;
  /* scroll-behavior: smooth; */
}

.react-rotating-text-cursor {
  animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s
    infinite;
  margin-left: 2px;
}

@keyframes blinking-cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#rotatingText span {
  font-size: 45px;
}

.overflow-y-scroll::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
}
