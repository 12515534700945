body {
  background: #000000;
  color: #999;
  font-size: 16px !important;
  font-weight: 400;
}
#contact a {
  text-decoration: none !important;
  color: #999 !important;
}
#loading {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
}
#footer svg {
  border: 2px solid #ffffff;
  border-radius: 50%;
  padding: 0.8rem;
  color: #d43f52;
  width: 50px;
  height: 50px;
  line-height: 45px;
  text-align: center;
  margin-right: 20px;
  /* autoprefixer: off */
  transition: all 0.4s;
}

.portfolio-overlay a {
  color: white !important;
}
.portfolio-overlay a:hover {
  color: #000000 !important;
  text-decoration: none;
}

.topBotm {
  margin-top: 60px;
  margin-bottom: 30px;
}

#footer svg:hover {
  background-color: #ffffff;
}

#contact svg {
  text-decoration: none !important;
  color: #999 !important;
}
#contact svg:hover {
  color: #d43f52 !important;
}

.gitpro {
  display: flex;
  justify-content: center;
}
#portfolio .btn {
  background: transparent;
  border: 2px solid #ffffff;
  color: #ffffff;
  font-size: 16px;
  text-transform: none;
  font-weight: bold;
  width: 180px;
  height: 60px;
  margin-top: 40px;
  padding-top: 18px;
  /* autoprefixer: off */
  transition: all 0.4s ease-in-out;
}
#portfolio p {
  margin-top: 60px;
  margin-right: 20px;
}
#portfolio .btn:hover {
  background-color: #d43f52;
}

.githubpro {
  margin-top: 70px;
}

.cvtext {
  color: #d43f52 !important;
  font-weight: 700;
}

/* start all heading */
h1,
h2,
h3,
h4,
h5 {
  color: #ffffff;
  font-weight: bold;
}

/* end all heading */

/* start all section */
#work,
#about,
#portfolio,
#contact {
  padding-top: 100px;
  padding-bottom: 120px;
}

/* end all section */

/* start home */
#home {
  /* background: url("./assets/images/me.jpg"); */
  background-color: rgba(0, 0, 0, 0.7);
  background-position: top;
  background-blend-mode: multiply;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 120px;
  padding-bottom: 140px;
  text-align: center;
}

#home h1 {
  border: 2px solid #d43f52;
  color: #d43f52;
  display: inline-block;
  letter-spacing: 6px;
  padding: 25px 30px;
  margin: 30px 0;
  /* width: 36rem; */
}

#home h2 {
  padding-bottom: 10px;
}

#home .btn {
  background: transparent;
  border: 2px solid #ffffff;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  text-transform: none;
  width: 180px;
  height: 60px;
  margin-top: 40px;
  padding-top: 18px;
  /* autoprefixer: off */
  transition: all 0.4s ease-in-out;
}

#home .btn:hover {
  background: #d43f52;
  border-color: transparent;
}

/* end home */

/* start work */
#work {
  background: #1a1a1a;
}

#work h2 {
  text-align: center;
}

#work .media {
  margin-top: 30px;
}

#work .media svg {
  border: 2px solid #ffffff;
  border-radius: 50%;
  color: #d43f52;
  font-size: 24px !important;
  padding: 0.9rem;
  margin-right: 24px;
  width: 60px;
  height: 60px;
  line-height: 55px;
  text-align: center;
  vertical-align: middle;
  /* autoprefixer: off */
  transition: all 0.4s ease-in;
}

#work .media svg:hover {
  background: #ffffff;
  cursor: pointer;
}

#work .media .media-heading {
  font-weight: 600;
  font-size: 20px;
  padding-bottom: 10px;
}

/* end work */

/* start about */
#about h4 {
  padding: 15px 0;
  color: #d43f52;
}

#about .progress {
  border-radius: 4px;
  background: #ffffff;
  height: 4px;
}

#about .progress .progress-bar-danger {
  background: #d43f52;
}

#about .text-top {
  padding-top: 50px;
}

#about span {
  color: #ffffff;
  font-weight: bold;
  display: block;
  padding-bottom: 6px;
}

#about small {
  font-weight: bold;
  float: right;
}

/* end about */

/* start portfolio */
#portfolio {
  background: #1a1a1a;
  text-align: center;
}

#portfolio h2 {
  padding-bottom: 30px;
}

/* start contact */

#contact address {
  padding-bottom: 18px;
}

#contact .contact-title {
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 20px;
  color: #ffffff;
}

#contact svg {
  border: 2px solid #ffffff;
  border-radius: 50%;
  color: #d43f52;
  font-size: 24px;
  padding: 0.8rem;
  width: 50px;
  height: 50px;
  line-height: 45px;
  text-align: center;
  margin-right: 20px;
}

#contact .form-control {
  background: transparent;
  border: 2px solid #999;
  border-radius: 0;
  box-shadow: none;
  color: #ffffff;
  margin-bottom: 20px;
}

#contact input {
  height: 50px;
}

#contact button {
  background: #d43f52 !important;
  border: 2px solid transparent !important;
  color: #ffffff;
  font-weight: bold;
  padding: 10px 0px 10px;
  /* autoprefixer: off */
  transition: all 0.4s ease-in-out;
}

#contact button:hover {
  background: #ffffff !important;
  color: #d43f52;
}

/* end contact */

/* start footer */
footer {
  border-top: 1px solid #090909;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 20px;
}

footer hr {
  width: 100px;
}

/* end footer */

/* start social icon */
.social-icon {
  position: relative;
  padding: 0;
  margin: 0;
}

.social-icon li {
  display: inline-block;
  list-style: none;
}

.social-icon li a {
  border-radius: 50%;
  color: #606060;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
  text-align: center;
  margin-right: 10px;
}

.social-icon li a:hover {
  color: #d43f52;
}

/* end social icon */

.fs-7 {
  font-size: 15px;
}

.lidecoration {
  list-style: none;
}
#breadcrumb {
  font-size: 15px;
}

.form-control {
  margin-top: 5px;
  margin-bottom: 5px;
  border: 1px solid white;
  border-radius: 0px;
  min-height: 35px;
}
.form-control:focus {
  border-color: #d43f52 !important;
  box-shadow: none !important;
}
.form-control::placeholder,
::file-selector-button {
  color: white !important;
}
.navigationLi {
  list-style-type: none;
}
.navigationLi li {
  margin: 0 10px;
}
.h-90 {
  height: 90%;
}

@media only screen and (max-width: 760px) {
  #work .justify-content-between {
    justify-content: flex-start !important;
  }
  #portfolio .portfolio-thumb {
    height: 50%;
  }
  .res-gap {
    gap: 1rem;
  }
  .PostImage {
    height: 250px;
  }
}

.postContainer {
  position: relative;
  /* width: 50%; */
}
.hover-blue:hover {
  color: violet;
}

.PostImage {
  display: block;
  width: 100%;
  height: 180px;
  object-fit: cover;
  object-position: 100% 0;
}
.ProjectContentIMG {
  width: 100%;
  height: 150px;
  object-fit: cover;
  object-position: 100% 0;
}

.Imageoverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #d43f52;
  overflow: hidden;
  width: 100%;
  height: 0;
  /* autoprefixer: off */
  transition: 0.5s ease;
}

.postContainer:hover .Imageoverlay {
  height: 100%;
}

.overlayText {
  white-space: nowrap;
  color: white;
  font-size: 20px;
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 50%;
  /* autoprefixer: off */
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
.showMoreBG {
  backdrop-filter: blur(10px);
  height: 100px;
}
.showMoreBG a:hover {
  background-color: #d43f52 !important;
  /* autoprefixer: off */
  transition: 0.5s ease;
}
.mt-n5 {
  margin-top: -5rem !important;
}

input:focus {
  outline: none !important;
}
.dropstart {
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}
